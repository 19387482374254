(function ( $ ) {
     $.fn.ajaxLink = function (options) {

        var objLink    = $(this);

        function _init() {
            objLink.each(function (index) {
                _click($(this));
                _disableLinks($(this));
            });
        }
        function _disableLinks(o){
            $(o).find('a').click(function(e) {
                e.preventDefault();
                return true;
            });
        }
        function _click(o){
            $(o).click(function() {
                href = '';
                href = $(this).find("a").attr("href");
                if(href != '')   window.location=$(this).find("a").attr("href");
                return false;
            });
        }


        _init();

       return {
         reload:_init,
       };
    };
}( jQuery ));

(function ( $ ) {
 $.fn.ajaxLoader = function (options) {
     var settings = $.extend({
        someOption: ""
    }, options);

    var ajaxLoader    = $(this);
    var ajaxContainer = $(this).find('.ajaxConteiner');
    var ajaxMoreBtn   = $(this).find('.ajaxMoreNews');


     //function definitions:
    function _init() {
        ajaxMoreBtn.click(function() {
            _loadMore();
        });
    }

    function _loadMore() {
        action = ajaxMoreBtn.attr('data-call');
        paged  = ajaxMoreBtn.attr('data-page');
        tax    = ajaxMoreBtn.attr('data-tax');

        ajaxMoreBtn.closest('.btns').addClass('loading');

        $.ajax({
            url : ajaxurl,
            data : {'action': action, 'paged': paged, 'ids': tax },
            type : 'POST',
            success : function( data ){
                if(data.html != null){
                    ajaxContainer.append(data.html);
                    $('.new').ajaxLink().reload();
                }
                if(data.paged != null && data.paged > 0){
                    ajaxMoreBtn.attr('data-page',data.paged);
                }else{
                    ajaxMoreBtn.addClass('hidden');
                }
                ajaxMoreBtn.closest('.btns').removeClass('loading');
            }
        });
        return this;
    }

    this.each(function () {
        _init();
    });

   return {
     reload:_init,
   };
};
}( jQuery ));

$('.new').ajaxLink();
$('#news').ajaxLoader();
